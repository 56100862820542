import React, { useState } from "react";
import { Col, Container, Row, Card, CardHeader, CardBody } from "reactstrap";
import { Breadcrumbs, H5, H6, P } from "../AbstractElements";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";
import api from "../api";
import DataTable from "react-data-table-component";
import {
  Avatar,
  Badge,
  Button,
  Drawer,
  Group,
  InputLabel,
  Modal,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { Edit, Trash2 } from "react-feather";
import { getStatusColor } from "../utils/functions";
import { IconInputSearch } from "@tabler/icons-react";
import { host } from "../static";
import { useDisclosure } from "@mantine/hooks";
import Belt from "../Components/Belt/Belt";
import { DatePickerInput } from "@mantine/dates";
import moment from "moment";

const Students = () => {
  const [students, setStudents] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [drawerOpen, drawerHandler] = useDisclosure(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [deleteModalOpen, deleteModalHandlers] = useDisclosure(false);
  const [assignRankOpened, assignRankHandlers] = useDisclosure(false);
  const [confirmStudentOpened, confirmStudentHandlers] = useDisclosure(false);

  const openConfirmStudent = (student) => {
    setSelectedStudent(student);
    confirmStudentHandlers.open();
  };

  const closeConfirmStudent = () => {
    setSelectedStudent(null);
    confirmStudentHandlers.close();
  };

  const openAssignRank = (student) => {
    setSelectedStudent(student);
    assignRankHandlers.open();
  };

  const closeAssignRank = () => {
    setSelectedStudent(null);
    assignRankHandlers.close();
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => (
        <div className="flex items-center">
          <Avatar
            radius="xl"
            size={"lg"}
            src={`${host}/public/${row?.profilePic}`}
          />
          <div className="flex flex-col m-2">
            <h6 className="m-0">
              {row.firstName} {row.lastName}{" "}
              <Badge size="sm" color={row.deleted ? "red" : "green"}>
                {row.deleted ? "Inactive" : "Active"}
              </Badge>
            </h6>
            <span>{row.email}</span>
            <span>{row.phoneNumber}</span>
          </div>
        </div>
      ),
      width: "300px",
    },
    {
      name: "Type",
      width: "250px",
      selector: (row) =>
        row.type === "Guest" ? (
          row.isStudent ? (
            <Button
              size="compact-sm"
              variant="outline"
              onClick={() => openConfirmStudent(row)}
            >
              Give student access
            </Button>
          ) : (
            <Badge color="grey">Guest</Badge>
          )
        ) : (
          <Badge color="black">Student</Badge>
        ),
    },
    {
      name: "Membership Status",
      selector: (row) =>
        row.type === "Student" && row.memberships[0] ? (
          <Badge color={getStatusColor(row.memberships[0]?.status)}>
            {row.memberships[0]?.status}
          </Badge>
        ) : (
          <span>-</span>
        ),
    },
    {
      name: "Nr of memberships",
      selector: (row) => row.memberships.length,
    },
    {
      name: "Classes (Attended / Enrolled)",
      selector: (row) => (
        <P>
          {row.attendedClasses.length} / {row.enrolledClasses.length}
        </P>
      ),
    },
    {
      name: "Rank",
      selector: (row) =>
        row?.type === "Student" && row.rank ? (
          <Badge
            color={
              row.rank?.rankInfo?.name === "White belt"
                ? "grey"
                : row.rank?.rankInfo?.color
            }
          >
            {row.rank?.rankInfo?.name}
          </Badge>
        ) : row?.type === "Student" && row?.tempRank?.rankInfo !== null ? (
          <Button
            title=""
            variant="outline"
            size="compact-sm"
            onClick={() => openAssignRank(row)}
          >
            Validate rank
          </Button>
        ) : (
          <span>No rank</span>
        ),
    },
    {
      name: "Actions",
      selector: (row) => (
        <div>
          <Button
            size="compact-sm"
            variant="outline"
            className="mx-2"
            onClick={() => {
              setSelectedStudent(row);
              drawerHandler.open();
            }}
          >
            <Edit size={13} />
          </Button>
          <Button
            size="compact-sm"
            variant="outline"
            color="red"
            onClick={() => {
              setSelectedStudent(row);
              deleteModalHandlers.open();
            }}
          >
            <Trash2 size={13} />
          </Button>
        </div>
      ),
    },
  ];

  const queryClient = useQueryClient();

  const assignRank = useMutation({
    mutationFn: async (data) => {
      try {
        const response = await api.post(`/student/confirm-rank`, data);
        return response.data;
      } catch (error) {
        toast.error("Error", { description: error.message });
        throw new Error(error.message);
      }
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ["getStudents"] });
      toast.success("Rank updated ! Parabéns !");
      closeAssignRank();
    },
  });

  const handleAssignRank = () => {
    assignRank.mutate({
      studentId: selectedStudent?._id,
    });
  };

  const confirmStudent = useMutation({
    mutationFn: async (data) => {
      try {
        const response = await api.post(`/student/confirm-is-student`, data);
        return response.data;
      } catch (error) {
        toast.error("Error", { description: error.message });
        throw new Error(error.message);
      }
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ["getStudents"] });
      toast.success("Student updated !");
      closeConfirmStudent();
    },
  });

  const handleConfirmStudent = (isStudent) => {
    confirmStudent.mutate({
      studentId: selectedStudent?._id,
      isStudent,
    });
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredStudents = students.filter((student) => {
    const fullName = `${student.firstName} ${student.lastName}`.toLowerCase();
    const phoneNumber = student.phoneNumber.toLowerCase();
    const email = student.email.toLowerCase();
    const rankName = student.rank?.rankInfo?.name.toLowerCase() || "";
    const searchTermLower = searchTerm.toLowerCase();

    return (
      fullName.includes(searchTermLower) ||
      phoneNumber.includes(searchTermLower) ||
      email.includes(searchTermLower) ||
      rankName.includes(searchTermLower)
    );
  });

  const { isFetching } = useQuery({
    queryKey: ["getStudents"],
    queryFn: async () => {
      try {
        const response = await api.post("/student/list");
        setStudents(response.data);
      } catch (error) {
        toast.error("Error", { description: error.message });
        throw new Error(error.message);
      }
    },
  });

  const handleChange = ({ target }) => {
    setSelectedStudent({ ...selectedStudent, [target.name]: target.value });
  };

  const closeModal = () => {
    deleteModalHandlers.close();
    drawerHandler.close();
    setSelectedStudent(null);
  };

  const deleteStudent = useMutation({
    mutationFn: async () => {
      try {
        const response = await api.post(
          `student/delete/${selectedStudent?._id}`
        );
        return response.data;
      } catch (error) {
        toast.error("Error", { description: error.message });
        throw new Error(error.message);
      }
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ["getStudents"] });
      closeModal();
      toast.success("Student deleted successully");
    },
  });

  return (
    <>
      <Breadcrumbs mainTitle="Manage students" title="Students" />
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <TextInput
                  type="search"
                  className="w-1/3"
                  leftSection={<IconInputSearch />}
                  placeholder="Type to search for a student by name, email, phone or rank..."
                  onChange={handleSearch}
                />
              </CardHeader>
              <CardBody>
                <DataTable
                  progressPending={isFetching}
                  pagination
                  columns={columns}
                  data={filteredStudents}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Drawer
        title="Edit student"
        onClose={closeModal}
        opened={drawerOpen}
        position="right"
      >
        <Text>
          Registered since :{" "}
          {moment(selectedStudent?.createdAt).format("DD/MM/YYYY")}
        </Text>
        <TextInput
          label="First name"
          name="firstName"
          value={selectedStudent?.firstName}
          onChange={handleChange}
        />
        <TextInput
          label="Last name"
          name="lastName"
          value={selectedStudent?.lastName}
          onChange={handleChange}
        />
        <TextInput
          label="Username"
          name="username"
          value={selectedStudent?.username}
          onChange={handleChange}
        />
        <TextInput
          label="Email"
          name="email"
          value={selectedStudent?.email}
          onChange={handleChange}
        />
        <TextInput
          label="Phone number"
          name="phoneNumber"
          value={selectedStudent?.phoneNumber}
          onChange={handleChange}
        />
        <Title size={"h3"} className="mt-2">
          Rank
        </Title>
        {selectedStudent?.type === "Student" ? (
          selectedStudent?.tempRank?.rankInfo !== null ? (
            <Button title="" variant="outline">
              Validate rank
            </Button>
          ) : (
            <div>
              <Belt
                color={selectedStudent?.rank?.rankInfo?.color}
                notchColor={
                  selectedStudent?.rank?.rankInfo?.name === "Black belt"
                    ? "red"
                    : "black"
                }
              />
              <DatePickerInput
                label="Obtention date"
                value={moment(selectedStudent?.rank?.dateOfObtention)}
                placeholder="Pick a date"
                disabled
              />
            </div>
          )
        ) : (
          <span>No rank</span>
        )}
        <Group justify="flex-end" className="mt-4">
          <Button variant="outline" onClick={closeModal}>
            Cancel
          </Button>
          <Button>Save</Button>
        </Group>
      </Drawer>
      <Modal
        opened={assignRankOpened}
        onClose={closeAssignRank}
        title="Validate student rank"
      >
        <p>Are you sure you want to validate the rank of this student?</p>
        <div className="my-2">
          <span className="font-bold">
            {selectedStudent?.tempRank?.rankInfo?.name}
          </span>
          <Belt
            color={selectedStudent?.tempRank?.rankInfo?.color}
            notchColor={
              selectedStudent?.tempRank?.rankInfo?.name === "Black belt"
                ? "red"
                : "black"
            }
          />
          <DatePickerInput
            label="Obtention date"
            value={moment(selectedStudent?.tempRank?.dateOfObtention)}
            placeholder="Pick a date"
          />
        </div>
        <Group justify="flex-end" className="mt-4">
          <Button color="red" variant="outline" onClick={closeAssignRank}>
            Cancel
          </Button>
          <Button onClick={handleAssignRank} loading={assignRank.isPending}>
            Confirm this rank
          </Button>
        </Group>
      </Modal>
      <Modal
        opened={confirmStudentOpened}
        onClose={closeConfirmStudent}
        title="Confirm that this is a student"
      >
        <p>
          Do you confirm {selectedStudent?.firstName}{" "}
          {selectedStudent?.lastName} is a student of Power Academy?
        </p>
        <Group justify="flex-end" className="mt-4">
          <Button color="red" variant="outline" onClick={closeAssignRank}>
            Cancel
          </Button>
          <Button
            onClick={() => handleConfirmStudent(false)}
            color="red"
            loading={confirmStudent.isPending}
          >
            No, this is not a student
          </Button>
          <Button
            onClick={() => handleConfirmStudent(true)}
            loading={confirmStudent.isPending}
          >
            Yes, this is a student
          </Button>
        </Group>
      </Modal>
      <Modal
        opened={deleteModalOpen}
        onClose={deleteModalHandlers}
        title={"Delete student"}
      >
        <p>
          Are you sure you want to delete{" "}
          <strong>
            {selectedStudent?.firstName} {selectedStudent?.lastName}
          </strong>
          ?
        </p>
        <Group justify="flex-end" className="mt-5">
          <Button variant="outline" onClick={deleteModalHandlers.close}>
            Cancel
          </Button>
          <Button color="red" onClick={() => deleteStudent.mutate()}>
            Delete
          </Button>
        </Group>
      </Modal>
    </>
  );
};

export default Students;
